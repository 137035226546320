import HttpService from "./HttpService";

const Constants = require("../views/constants/Constants");
const WEB = "Web";

export default class UserService {
  // login
  static login(email, pass, cb, errCb) {
    HttpService.httpCall(
      "POST",
      Constants.API_ROUTES.USER.LOGIN,
      {
        email: email,
        password: pass,
      },
      undefined,
      cb,
      errCb
    );
  }

  // login with facebook
  static loginFb(facebookId, fbToken, email, cb, errCb) {
    HttpService.httpCall(
      "POST",
      Constants.API_ROUTES.USER.LOGIN_FB,
      {
        email: email,
        clientType: WEB,
        facebookId: facebookId,
        facebookToken: fbToken,
      },
      undefined,
      cb,
      errCb
    );
  }

  // login with google
  static loginGoogle(googleId, googleToken, email, cb, errCb) {
    HttpService.httpCall(
      "POST",
      Constants.API_ROUTES.USER.LOGIN_GOOGLE,
      {
        email: email,
        clientType: WEB,
        googleId: googleId,
        googleToken: googleToken,
      },
      undefined,
      cb,
      errCb
    );
  }

  // get all users
  static getAll(token, pagination, sort, filter, start, end, state, cb, errCb) {
    console.log(Constants.API_ROUTES.USER.GET_ALL);
    const path = new URL(Constants.API_ROUTES.USER.GET_ALL);

    Object.keys(pagination).forEach((key) =>
      path.searchParams.append(key, pagination[key])
    );

    const sortParam = sort.reduce(
      (accum, { column, ascending }, i) =>
        accum.concat(
          `${i === 0 ? "" : ","}${column}:${ascending ? "asc" : "desc"}`
        ),
      ""
    );
    path.searchParams.append("sort", sortParam);

    if (filter) path.searchParams.append("filter", filter);

    if (start) {
      const date = new Date(start);
      path.searchParams.append(
        "initAccessDate",
        date.getFullYear().toString() +
          ("0" + (date.getMonth() + 1)).slice(-2).toString() +
          ("0" + date.getDate()).slice(-2).toString()
      );
    }

    if (end) {
      const date = new Date(end);
      path.searchParams.append(
        "endAccessDate",
        date.getFullYear().toString() +
          ("0" + (date.getMonth() + 1)).slice(-2).toString() +
          ("0" + date.getDate()).slice(-2).toString()
      );
    }

    if (state) {
      path.searchParams.append(
        "state",
        state === "Activo" ? "active" : "inactive"
      );
    }

    HttpService.httpCall("GET", path, undefined, token, cb, errCb);
  }

  // get users for export to excel
  static getAllForExport(token, sort, filter, start, end, state, cb, errCb) {

    console.log(Constants.API_ROUTES.USER.GET_ALL_EXPORT);
    const path = new URL(Constants.API_ROUTES.USER.GET_ALL_EXPORT);

    path.searchParams.append("sort", sort);

    if (filter) path.searchParams.append("filter", filter);

    if (start) {
      const date = new Date(start);
      path.searchParams.append(
        "initAccessDate",
        date.getFullYear().toString() +
          ("0" + (date.getMonth() + 1)).slice(-2).toString() +
          ("0" + date.getDate()).slice(-2).toString()
      );
    }

    if (end) {
      const date = new Date(end);
      path.searchParams.append(
        "endAccessDate",
        date.getFullYear().toString() +
          ("0" + (date.getMonth() + 1)).slice(-2).toString() +
          ("0" + date.getDate()).slice(-2).toString()
      );
    }

    if (state) {
      path.searchParams.append(
        "state",
        state === "Activo" ? "active" : "inactive"
      );
    }

    HttpService.httpCall("GET", path, undefined, token, cb, errCb);
  }

  // get current user
  static getByToken(token, cb, errCb) {
    HttpService.httpCall(
      "GET",
      Constants.API_ROUTES.USER.GET_TOKEN,
      undefined,
      token,
      cb,
      errCb
    );
  }

  // get user by id
  static getById(token, userId, cb, errCb) {
    HttpService.httpCall(
      "GET",
      Constants.API_ROUTES.USER.GET(userId),
      undefined,
      token,
      cb,
      errCb
    );
  }

  // get stats
  static getStats(token, cb, errCb) {
    HttpService.httpCall(
      "GET",
      Constants.API_ROUTES.USER.GET_STATS,
      undefined,
      token,
      cb,
      errCb
    );
  }

  // create user
  static signup(
    token,
    email,
    password,
    name,
    lastName,
    role,
    company,
    status,
    emailCheck,
    cb,
    errCb
  ) {
    HttpService.httpCall(
      "POST",
      Constants.API_ROUTES.USER.SIGNUP,
      {
        email,
        password,
        name,
        lastName,
        company,
        role: role === "Administrador" ? "Admin" : "Client",
        active: status === "Activo",
        sendEmail: emailCheck,
      },
      token,
      cb,
      errCb
    );
  }

  // activate user account
  static activateAccount(userId, code, cb, errCb) {
    HttpService.httpCall(
      "POST",
      Constants.API_ROUTES.USER.ACTIVATE(userId, code),
      undefined,
      undefined,
      cb,
      errCb
    );
  }

  // activate user account while an admin (no code required)
  static activateAccountAdmin(token, userId, cb, errCb) {
    HttpService.httpCall(
      "POST",
      Constants.API_ROUTES.USER.ACTIVATE_ADMIN(userId),
      undefined,
      token,
      cb,
      errCb
    );
  }

  // change language
  static editLanguage(token, language, cb, errCb) {
    HttpService.httpCall(
      "PUT",
      Constants.API_ROUTES.USER.EDIT_LANGUAGE,
      {
        language: language,
      },
      token,
      cb,
      errCb
    );
  }

  // send password recovery email
  static sendPasswordRecoveryEmail(/*name,*/ email, cb, errCb) {
    HttpService.httpCall(
      "POST",
      Constants.API_ROUTES.USER.SEND_PASSWORD_RECOVERY_EMAIL,
      {
        // name: name,
        email: email,
      },
      undefined,
      cb,
      errCb
    );
  }

  // change password
  static editPassword(token, oldPassword, password, cb, errCb) {
    HttpService.httpCall(
      "POST",
      Constants.API_ROUTES.USER.EDIT_PASSWORD,
      {
        oldPassword: oldPassword,
        password: password,
      },
      token,
      cb,
      errCb
    );
  }

  // recover password
  static recoverPassword(userId, password, code, cb, errCb) {
    HttpService.httpCall(
      "POST",
      Constants.API_ROUTES.USER.RECOVER_PASSWORD(userId, code),
      {
        password: password,
      },
      undefined,
      cb,
      errCb
    );
  }

  // make admin
  static makeAdmin(token, userId, cb, errCb) {
    HttpService.httpCall(
      "POST",
      Constants.API_ROUTES.USER.MAKE_ADMIN(userId),
      undefined,
      token,
      cb,
      errCb
    );
  }

  // block user
  static blockUser(token, userId, cb, errCb) {
    HttpService.httpCall(
      "POST",
      Constants.API_ROUTES.USER.BLOCK(userId),
      undefined,
      token,
      cb,
      errCb
    );
  }

  // block user
  static unBlockUser(token, userId, cb, errCb) {
    HttpService.httpCall(
      "POST",
      Constants.API_ROUTES.USER.UNBLOCK(userId),
      undefined,
      token,
      cb,
      errCb
    );
  }

  // edit user
  static edit(
    token,
    userId,
    email,
    password,
    name,
    lastName,
    role,
    company,
    status,
    cb,
    errCb
  ) {
    HttpService.httpCall(
      "PUT",
      Constants.API_ROUTES.USER.EDIT(userId),
      {
        email,
        password: password === "" ? undefined : password,
        name,
        lastName,
        role: role === "Administrador" ? "Admin" : "Client",
        company,
        active: status === "Activo" ? true : false,
      },
      token,
      cb,
      errCb
    );
  }

  // delete user
  static delete(token, userId, cb, errCb) {
    HttpService.httpCall(
      "DELETE",
      Constants.API_ROUTES.USER.DELETE(userId),
      undefined,
      token,
      cb,
      errCb
    );
  }
}
