export const colors = {
  white: "white",
  blue: "#084C94",
  grey: "#828386",
  lightGrey: "#e3e5e8",
  extraLightGrey: "#EEEEEE",
  mediumGrey: "#C4C4C4",
  blueishGrey: "#78859D",
  neutralGrey: "#78859D",
  buttonGrey: "#B1B7C4",
  //lightBlue: '#4772b1',
  lightBlue: "#0ca6ff",
  darkBlue: "#07205d",
  backgound: "#F6F7F8",
  transparentBlue: "rgba(0, 71, 125, 0.1)",
  transparentLightBlue: "#E4F1F8",
  transparentGrey: "#78859D50",
  transparentBlue2: "#00478133",
  transparentBlue3: "#00478119",
  transparentBlue4: "#00478170",
  unread: "#E4F1F8",
  darkRed: "darkred",
};
