const API_URL =
  window._env_ && window._env_.REACT_APP_BACKEND
    ? window._env_.REACT_APP_BACKEND
    : process.env.REACT_APP_BACKEND;

const FILES_URL =
  window._env_ && window._env_.REACT_APP_FILES
    ? window._env_.REACT_APP_FILES
    : process.env.REACT_APP_FILES;

module.exports = {
  API_ROUTES: {
    USER: {
      GET_ALL_EXPORT: `${API_URL}/user/export`,
      GET_ALL: `${API_URL}/user/all`,
      GET_TOKEN: `${API_URL}/user/`,
      GET: (userId) => `${API_URL}/user/${userId}`,
      LOGIN: `${API_URL}/user/login`,
      LOGIN_FB: `${API_URL}/user/login/fb`,
      LOGIN_GOOGLE: `${API_URL}/user/login/google`,
      REFRESH_TOKEN: `${API_URL}/user/refreshToken`,
      SIGNUP: `${API_URL}/user/signup/`,
      ACTIVATE: (userId, code) => `${API_URL}/user/${userId}/activate/${code}`,
      ACTIVATE_ADMIN: (userId) => `${API_URL}/user/${userId}/activate/`,
      MAKE_ADMIN: (userId) => `${API_URL}/user/${userId}/admin/`,
      BLOCK: (userId) => `${API_URL}/user/${userId}/block/`,
      UNBLOCK: (userId) => `${API_URL}/user/${userId}/unblock/`,
      EDIT: (userId) => `${API_URL}/user/${userId}`,
      EDIT_LANGUAGE: `${API_URL}/user/language`,
      SEND_PASSWORD_RECOVERY_EMAIL: `${API_URL}/user/password/forgot`,
      RECOVER_PASSWORD: (userId, code) =>
        `${API_URL}/user/${userId}/password/recover/${code}`,
      EDIT_PASSWORD: `${API_URL}/user/password`,
      DELETE: (userId) => `${API_URL}/user/${userId}`,
      GET_STATS: `${API_URL}/user/number`,
    },
    REPORTS: {
      GET_ALL: `${API_URL}/report/all`,
      CREATE: `${API_URL}/report`,
      TESTING_EMAIL: `${API_URL}/report/testEmail`,
      VALIDATE: `${API_URL}/report/validate`,
      GET: (reportId) => `${API_URL}/report/${reportId}`,
      FILE: (fileCode) => `${FILES_URL}/${fileCode}`,
      EDIT: (contentId) => `${API_URL}/report/${contentId}`,
    },
    DEBT: {
      GET_DRAFT_GRAPHS: (frecuency) =>
        `${API_URL}/debtExpiration/draft/all/${frecuency}`,
      GET_DRAFT_TABLE: `${API_URL}/debtExpiration/draft/all`,
      GET_DRAFT_ASSETS: `${API_URL}/debtExpiration/draft/activesAndAssetClasses`,
      UPLOAD_DRAFT: `${API_URL}/debtExpiration/draft`,
      UPLOAD_LIVE: `${API_URL}/debtExpiration/live`,
    },
    MACRO: {
      GET_DRAFT: `${API_URL}/macro/all/draft`,
      UPLOAD_DRAFT: `${API_URL}/macro/draft`,
      POST_DRAFT: `${API_URL}/macro/publish`,
    },
    FEED: {
      GET_ALL: `${API_URL}/publication/all`,
      CREATE: `${API_URL}/publication`,
      FILE: (fileCode) => `${API_URL}/publication/file/${fileCode}`,
      DELETE: (id) => `${API_URL}/publication/${id}`,
    },
    METRICS: {
      GET_REPORT_COUNTERS: `${API_URL}/metrics/reportsCounters`,
    },
  },
  ROUTES: {
    ACCOUNT_ACTIVATION: "/activateAccount",
    PASSWORD_RECOVER: "/passwordRecovery",
    FORGOT: "/forgot",
    LOGIN: "/",
    LEAGUES: "/leagues",
    FEEDBACK: "/feedback",
    GAMES: "/games",
    GAME: (gameId) => "/game/" + gameId,
    TEAMS: (leagueId) => "/teams/" + leagueId,
    USER: (userId) => "/user/" + userId,
    REPORTED: "/report",

    USERS: "/users",
    DASHBOARD: "/dashboard",
    CONTENTS: "/contents",
    EDIT_USER: (userId) => "/users/" + userId,
    CREATE_USER: "/users/createUser",
    EDIT_CONTENT: (contentId) => "/contents/" + contentId,
    CREATE_CONTENT: "/contents/createContent",
    DEBT: "/debt",
    MACRO: "/macro",
    FEED: "/feed",
    PROJECTIONS: "/projections",
  },

  PAGINATION: {
    PAGE_SIZES: [10, 15, 20, 25, 50, 100],
  },

  USER_TYPES: {
    Admin: "Admin",
    Client: "Client",
  },

  TYPES: {
    TEXT: "Text",
    PARAGRAPH: "Paragraph",
    PASSWORD: "Password",
    DATE: "Date",
    EMAIL: "Email",
    PHONE: "Phone",
    NUMBER: "Number",
    BOOLEAN: "Boolean",
    CHECKBOX: "Checkbox",
  },

  FILE_TYPES: {
    IMAGE: "Imagen",
    AUDIO: "Audio",
    VIDEO: "Video",
    PDF: "Documento",
  },

  SHORT_INPUT_MAX_LEN: 150,
  LONG_INPUT_MAX_LEN: 5000,

  DASHBOARD: {
    FILTERS: {
      WEEK: "week",
      MONTH: "month",
      YEAR: "year",
      CUSTOM: "custom",
    },
  },

  DEFAULT_LANGUAGE: "es",
  AVAILABLE_LANGUAGES: ["en", "es"],
  API_URL,
};
