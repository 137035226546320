import React from "react";
import { ClipLoader } from "react-spinners";

const css =
  "z-index: 1; height: 100px; width: 100px; position: absolute; left: 50%; margin-left: -50px; top: 50%; margin-top: -50px;";

export default class Spinner {
  // renders a spinning wheel when loading is true
  static render(loading) {
    // return <ClipLoader css={css} size={100} loading={loading} />;
    return (
      <div
        style={{
          display: loading ? "" : "none",
          position: "sticky",
          top: "50vh",
        }}
      >
        <img
          src={require("../../assets/loader2.gif")}
          style={{
            display: loading ? "" : "none",
            position: "relative",
            height: 100,
            width: 100,
            left: "50%",
            marginLeft: -50,
            top: "50%",
            marginTop: -50,
          }}
        />
      </div>
    );
  }
}
